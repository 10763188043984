const NewWatches = [
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex'],

        "id": 7826,
        "product_id": 10247,
        "name": "Tudor BLACK BAY",
        "price": 1200,
        "on_sale": true,
        "regular_price": "",
        "sale_price": "",
        "list_price": 1200,
        "product_currency": {
            "currency_id": 96,
            "name": "Dinar",
            "name_plural": "Dinar",
            "code": "KWD",
            "symbol": "KWD",
            "thousand": ",",
            "decimal": ".",
            "symbolOnLeft": true,
            "spaceBetweenAmountAndSymbol": false,
            "precision": 2,
            "format": "%s%v"
        },
        "permalink": "",
        "date_created": "2023-03-01 23:59:54",
        "date_created_gmt": "2023-03-01 23:59:54",
        "date_modified": "2023-10-28 23:22:36",
        "date_modified_gmt": "2023-03-01 23:59:54",
        "type": "product",
        "status": true,
        "is_qty_available_on_request": true,
        "approval_state": false,
        "condition": "New",
        "scope_of_delivery": "",
        "seller": {
            "id": 501,
            "name": "F A",
            "country_id": 122,
            "country_name": "Kuwait",
            "country_code": "KW",
            "avatar_url": "http://192.168.29.103:8069/web/image/?model=res.partner&id=501&field=image",
            "is_seller_verified": false,
            "seller_type": "Bronze Seller",
            "listing_count": 16,
            "sold_watch_count": 2,
            "rating_count": 0,
            "since": "Mar 2020",
            "average_rating": 0,
            "rating_stats": {
                "avg": 0,
                "total": 0,
                "percent": {
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 0,
                    "5": 0,
                    "6": 0,
                    "7": 0,
                    "8": 0,
                    "9": 0,
                    "10": 0
                }
            }
        },
        "manage_stock": true,
        "stock_quantity": 9999969,
        "in_stock": false,
        "availability_status": "not_available",
        "product_brand_id": {
            "id": 64,
            "name": "Tudor"
        },
        "watch_category_id": {
            "id": 1040,
            "name": "BLACK BAY"
        },
        "model_year_id": {
            "id": 138,
            "name": "2018"
        },
        "status_watch_id": {
            "id": 1,
            "name": "New"
        },
        "movement_id": {
            "id": 1,
            "name": "Automatic"
        },
        "bracelet_type_id": {
            "id": 9,
            "name": "Steel"
        },
        "gender_id": {
            "id": 1,
            "name": "Male"
        },
        "watch_condition_id": {
            "id": false,
            "name": false
        },
        "other_watch_condition": false,
        "watch_link_id": {
            "id": false,
            "name": false
        },
        "scope_of_delivery_id": {
            "id": false,
            "name": false
        },
        "other_scope_of_delivery": false,
        "size": "41",
        "description": "TUDOR Black Bay harrods edition\n41 mm\nFull set\nBrand new 2018",
        "date_on_sale_to": false,
        "tax_status": "taxable",
        "tax_class": "",
        "average_rating": 0,
        "rating_count": 0,
        "related_ids": [],
        "images": [
            {
                "id": 54185,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54185&field=image",
                "name": "Image1",
                "alt": "",
                "position": 1,
                "image_type": "dial"
            },
            {
                "id": 54187,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54187&field=image",
                "name": "Image2",
                "alt": "",
                "position": 2,
                "image_type": "left"
            },
            {
                "id": 54186,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54186&field=image",
                "name": "Image3",
                "alt": "",
                "position": 3,
                "image_type": "right"
            },
            {
                "id": 54188,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54188&field=image",
                "name": "Image4",
                "alt": "",
                "position": 4,
                "image_type": "cover"
            },
            {
                "id": 54189,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54189&field=image",
                "name": "Image5",
                "alt": "",
                "position": 5,
                "image_type": "buckle"
            },
            {
                "id": 54190,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54190&field=image",
                "name": "Image6",
                "alt": "",
                "position": 6,
                "image_type": "open_buckle"
            },
            {
                "id": 54191,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54191&field=image",
                "name": "Image7",
                "alt": "",
                "position": 7,
                "image_type": "band"
            },
            {
                "id": 54192,
                "src": "http://192.168.29.103:8069/web/image/?model=product.image&id=54192&field=image",
                "name": "Image8",
                "alt": "",
                "position": 8,
                "image_type": "box_paper"
            }
        ],
        "attributes": [
            {
                "id": 53270,
                "name": "Brand",
                "position": 0,
                "visible": true,
                "variation": true,
                "options": [
                    "Tudor"
                ]
            },
            {
                "id": 53271,
                "name": "Model",
                "position": 1,
                "visible": true,
                "variation": true,
                "options": [
                    "BLACK BAY"
                ]
            },
            {
                "id": 53272,
                "name": "Model Year",
                "position": 2,
                "visible": true,
                "variation": true,
                "options": [
                    "2018"
                ]
            },
            {
                "id": 53273,
                "name": "Bracelet Type",
                "position": 3,
                "visible": true,
                "variation": true,
                "options": [
                    "Steel"
                ]
            },
            {
                "id": 53274,
                "name": "Status Watch",
                "position": 4,
                "visible": true,
                "variation": true,
                "options": [
                    "New"
                ]
            },
            {
                "id": 53275,
                "name": "Movement",
                "position": 5,
                "visible": true,
                "variation": true,
                "options": [
                    "Automatic"
                ]
            },
            {
                "id": 53276,
                "name": "Gender",
                "position": 6,
                "visible": true,
                "variation": true,
                "options": [
                    "Male"
                ]
            }
        ],
        "default_attributes": [],
        "variations": [],
        "better_featured_image": false,
        "product_serial_number": "I816922",
        "product_share_url": "http://192.168.29.103:8069/shop/product/tudor-black-bay-7826",
        "retail_price": "",
        "price_request_data": {},
        "allow_req_special_price": true,
        "product_type": "watch",
        "ImageUploadedByNewWay": true,
        "product_view_count": 978,
        "has_product_discount": false,
        "discount_price": 0,
        "final_product_price": 1200,
        "discount_tag": false,
        "is_sold_in_last_month": true,
        "last_sold_price": 1000,
        "last_sold_date": "2023-03-21",
        "show_waitlist_btn": false,
        "already_subscribed_for_wait_list": false,
        "product_rating_stats": {
            "avg": 0,
            "total": 0,
            "percent": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0
            }
        },
        "lower_price": 0,
        "other_brand": "",
        "other_category": ""
    },
    
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Date Just',
        strap: 'Black Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Date Just 2',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    },
    {
        brandName: 'Rolex',
        model: 'Daytona',
        strap: 'Gold Strap',
        price: '8,000',
        currency: 'KD',
        isSpecialPrice: true,
        country: 'Kuwait',
        tags: ['Kuwait', 'New', 'Rolex']
    }
];

export { NewWatches };