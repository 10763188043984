import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios.js";

export const fetchAllAccessories = createAsyncThunk(
  "categories/fetchAllAccessories",
  async ({ per_page, page, categoryId, product_type }) => {
    const response = await axiosInstance.post("/gmt/get_all_products", {
      per_page,
      page,
      order: "desc",
      orderby: "approval_date",
      on_sale: null,
      categoryId,
      product_type: 'accessory',
    });
    return {
      data: response.data.result,
      is_more: response.data.result?.length === per_page ? true : false,
    };
  }
);


const initialState = {
  accessories: {
    status: "idle",
    products: [],
    error: null,
    is_more: true,
  },
};

const accessorySlice = createSlice({
  name: "accessories",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAccessories.pending, (state) => {
        state.accessories.status = "loading";
      })
      .addCase(fetchAllAccessories.fulfilled, (state, action) => {
        state.accessories.status = "succeeded";
        state.accessories.products = [
          ...state.accessories.products,
          ...action.payload.data,
        ];
        state.accessories.is_more = action.payload.is_more;
      })
      .addCase(fetchAllAccessories.rejected, (state, action) => {
        state.accessories.status = "failed";
        state.accessories.error = action.error.message;
      });
  },
});


export default accessorySlice.reducer;
