import React from 'react'
import HeroButton from './HeroButton'
import HeroAction from './HeroAction'
import { useNavigate } from 'react-router-dom';


const Hero = () => {
  const navigation = useNavigate();

  return (
    <div className={`bg-background800 text-tiny text-white flex flex-col justify-center items-center md:items-center md:flex-row md:justify-between md:px-16 md:pt-12 md:pb-5`}>
        <div>
            <HeroAction />
        </div>
        
        <div className={`flex flex-col justify-center items-center gap-3`}>
            <HeroButton 
              startingText="New in"
              heading="GMT24 APP"
              actionText="Download Now"
              onClick={() => console.log('first button clicked')}
            />
            <HeroButton 
              startingText="New in"
              heading="Watch"
              actionText="Shop Now"
              onClick={() => navigation('/watch')}

            />
            <HeroButton 
              startingText="New in"
              heading="Accessory"
              actionText="Shop Now"
              onClick={() => navigation('/accessory')}

            />
        </div>
    </div>
  )
}

export default Hero