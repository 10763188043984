import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RightArrow from "../assets/icons/Arrow-right.svg";
import Button from "../components/Button";

import Banner from "../assets/images/category.png";
import BannerSm from "../assets/images/category_sm.png";
import Crown from "../assets/icons/crown.png";
import { useLocation } from "react-router";
import { fetchSellerProducts } from "../store/sellerSlice";
import WatchCard from "../components/WatchCard";
import { Spinner } from "@material-tailwind/react";

const per_page = 12;

// import Close_svg from "../assets/icons/Close.svg"
function Seller() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const seller = location.state.seller;
  const sellerDetail = useSelector((state) => state.seller);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchSellerProducts({ per_page: per_page, page: page, seller_id: seller.id }));
  }, [page]);

  const viewMore = () => setPage((page) => page + 1);

  return (
    <div className={`w-screen bg-background800 no-scrollbar`}>
      <div className="relative ">
        <img className=" sm:hidden w-full" src={BannerSm} />

        <img className="hidden sm:block h-[173px] sm:h-auto" src={Banner} />
        <div className="absolute inset-0 flex flex-col sm:items-center justify-start sm:justify-center">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row sm:flex-col justify-start sm:justify-center items-center mt-2 gap-3 sm:gap-0 ml-2 sm:ml-0">
              <img className="h-20 w-20 sm:h-24 sm:w-24" src={seller?.avatar_url} />
              <div className="flex flex-col justify-start items-start sm:justify-center sm:items-center gap-1 sm:gap-0">
                <span className="din-text text-base font-normal sm:font-thin uppercase sm:capitalize sm:text-4xl text-white mt-2">
                  {seller.name}
                </span>
                <div className="flex flex-row justify-start sm:justify-center items-center din-text  gap-2 ">
                  <img src={Crown} width={15} />
                  <span className="text-gold">
                    {seller?.seller_type}
                    {/* <span className="text-white">Seller</span> */}
                  </span>
                </div>
                <span className="text-whiteSecondary/20 sm:text-gold"> {seller?.rating_stats?.total === 0 ? 'No Rating' : seller.rating_stats.avg / seller.rating_stats.total}</span>
              </div>
            </div>
            <div className="sm:hidden flex justify-center items-center">
              <Button
                text={"View Ratings"}
                background={'bg-none'}
                isBorder={false}
                iconRight={RightArrow}
                onClick={() => console.log("Download App")}
                width={"w-[158px] md:w-[161px]"}
              // height={"h-[40px] md:h-[40px]"}
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center gap-[54px] mt-4">
            <div className="flex flex-col  ">
              <span className="text-whiteSecondary/20 text-center">Since</span>
              <span className="text-white/100 text-center">{seller?.since}</span>
            </div>
            <div className="flex flex-col border-l-4 border-black/[.20] rounded-s-sm  pl-4 ">
              <span className="text-whiteSecondary/20 text-center">
                Listing
              </span>
              <span className="text-white/100 text-center">{seller?.listing_count}</span>
            </div>
            <div className="flex flex-col border-l-4 border-black/[.20] rounded-s-sm pl-4">
              <span className="text-whiteSecondary/20 text-center">Sold</span>
              <span className="text-white/100 text-center">{seller?.sold_watch_count}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={` grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-9 px-0 sm:px-[30px] justify-items-center items-center`}>
          {sellerDetail.products &&
            sellerDetail.products?.map((value, index) => {
              return (
                <div key={index}>
                  <WatchCard
                    watchDetail={value}
                    page={page}
                    onClick={() => navigation("/product-detail")}
                  />
                </div>
              );
            })}
        </div>
        {sellerDetail?.status === "loading" && (
          <div className="flex justify-items-center ">
            <Spinner className="h-4 w-full " />
          </div>
        )}
        {sellerDetail?.is_more && (
          <div className={`hidden sm:flex sm:justify-center py-4`}>
            <Button
              text={"View More"}
              isBorder={false}
              onClick={() => viewMore()}
              width={"w-[161px] md:w-[161px]"}
              height={"h-[40px] md:h-[40px]"}
            />
          </div>
        )}
      </div>


    </div>
  );
}

export default Seller;
