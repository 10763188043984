import React, { useState } from "react";
import styles from "../style";
import { search, user, cart, menu } from "../assets/icons";
import { gmt24Logo } from "../assets/images";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigation = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className={`bg-background800 ${styles.flexCenter} px-8 py-4 gap-8 sm:(p-4)`}>
    <div className={`block md:hidden self-start p-2 ${styles.flexCenter}`}>
      <img
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} 
        src={menu}
        alt="hoobank"
        className="w-[16px] h-[16px] cursor-pointer"
      />
    </div>

    <img
      onClick={() => navigation("/")}
      src={gmt24Logo}
      alt="hoobank"
      className="w-[72px] h-[26px] cursor-pointer"
    />

    <ul className={`hidden md:flex gap-8 text-tiny text-white justify-center`}>
      <li onClick={() => navigation("/watch")} className={`cursor-pointer`}>Watch</li>
      <li onClick={() => navigation("/accessory")} className={`cursor-pointer`}>Accessories</li>
      <li onClick={() => navigation("/contact-us")} className={`cursor-pointer`}>Contact Us</li>
    </ul>

    {/* Mobile Menu */}
    {isMobileMenuOpen && (
      <ul className={`absolute top-16 left-0 w-full bg-background900 md:hidden flex flex-col gap-4 p-4 text-white justify-center items-center`}>
        <li onClick={() => { navigation("/watch"); setIsMobileMenuOpen(false); }} className={`cursor-pointer`}>Watch</li>
        <li onClick={() => { navigation("/accessory"); setIsMobileMenuOpen(false); }} className={`cursor-pointer`}>Accessories</li>
        <li onClick={() => { navigation("/contact-us"); setIsMobileMenuOpen(false); }} className={`cursor-pointer`}>Contact Us</li>
      </ul>
    )}

    <div className={`hidden md:flex w-auto h-[100%] p-2 rounded-lg bg-background900 cursor-pointer justify-between items-center grow-[4]`}>
      <input
        className={`bg-transparent text-white focus:outline-none text`}
        placeholder="Search"
      />
      <img src={search} alt="Search" className="w-[24px] h-[24px]" />
    </div>

    <div className={`${styles.flexCenter} gap-4`}>
      <img
        onClick={() => navigation("/checkout-cart")}
        src={cart}
        alt="Cart"
        className="w-[32px] h-[28px] cursor-pointer"
      />
      <img
        onClick={() => navigation("/account")}
        src={user}
        alt="Profile"
        className="w-[24px] h-[24px] cursor-pointer"
      />
    </div>
  </nav>  
);
};

export default Header;
