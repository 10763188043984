import React, { useState } from 'react'

function HorizontalTabl({tabs}) {
    const [activeTab, setActiveTab] = useState(tabs[0]?.key)

  return (
    <div className={`flex flex-row justify-center items-center`}>
   
        {
            tabs.map((item, index) => {
                return(
                    <div 
                        className={`flex flex-col justify-center items-center w-44 h-9 border-1 ${activeTab === item.key ? 'bg-background900' : 'bg-transparent'} border-background900 cursor-pointer`}
                        onClick={(item) => {
                            setActiveTab(item?.key);
                        }}
                        key={item.key}
                    >
                        <span className={`text ${activeTab === item.key ? 'text-gold' : 'text-white'}  text-base`}>{item.value}</span>
                    </div>
                )
            } )
        }
    </div>
  )
}

export default HorizontalTabl