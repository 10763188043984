import React from 'react'

const GMTFeatures = () => {
  return (
    <div className={`flex flex-col gap-[6px] pb-[41px] din-text`}>
    <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
      {" "}
      <div className="w-3 h-3 rounded-full bg-gold"></div>
      <span>Negotiate Prices</span>
    </div>
    <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
      {" "}
      <div className="w-3 h-3 rounded-full bg-gold"></div>
      <span>Special Offers</span>
    </div>
    <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
      {" "}
      <div className="w-3 h-3 rounded-full bg-gold"></div>
      <span>Discover Merchants</span>
    </div>
    <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
      {" "}
      <div className="w-3 h-3 rounded-full bg-gold"></div>
      <span>Sell your collection</span>
    </div>
  </div>
  )
}

export default GMTFeatures