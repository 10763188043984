import React from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import Button from "./Button";
import WatchCard from "./WatchCard";
const NewInWatchSection = ({ products, header, status }) => {
  const navigate = useNavigate();


  const handleViewAll = () => {
    navigate("/watch");
  };
  return (
    <>
      <div
        className={`flex flex-col justify-center items-center sm:px-9 pl-4 py-4 gap-2 mt-10 sm:mt-[101px] sm:my-0 `}
      >
        <div
          className={`flex flex-col justify-center items-center gap-2 sm:mb-1 `}
        >
          <span className={`text-small-green`}>New in</span>
          <span
            className={` title text-haeder tracking-[.12px] text-2xl sm:text-5xl sm:tracking-[.24px] mb-0 sm:mb-4`}
          >
            {header}
          </span>
        </div>
        <div
          className={`flex sm:grid md:grid-cols-3 lg:grid-cols-4 w-full relative  no-scrollbar overflow-x-scroll sm:gap-9 gap-4`}
        >
          {products &&
            products.slice(0, 8)?.map((value, index) => (
              <div key={index} className={` sm:block`}>
                {status === "idel" ? (
                  <Card className="mt-6 w-[188px] h-[200px] mb-0 sm:mb-9 sm:w-[300px] sm:h-[465px] rounded-none sm:rounded-[10px] animate-pulse bg-gray-900">
                    <CardHeader
                      shadow={false}
                      floated={false}
                      className="w-[150px] h-[140px] sm:w-[270px] sm:h-[286px] object-cover rounded-none sm:rounded-t-[10px] relative grid place-items-center bg-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-12 w-12 text-gray-900"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    </CardHeader>
                    <CardBody>
                      <Typography
                        as="div"
                        variant="h1"
                        className="mb-4 h-3 w-38 sm:h-3 sm:w-56 rounded-full bg-gray-500"
                      >
                        &nbsp;
                      </Typography>
                      <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-full rounded-full bg-gray-500"
                      >
                        &nbsp;
                      </Typography>
                      <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-full rounded-full bg-gray-500"
                      >
                        &nbsp;
                      </Typography>
                      <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-full rounded-full bg-gray-500"
                      >
                        &nbsp;
                      </Typography>
                    </CardBody>
                    <CardFooter className="pt-0">
                      <Button
                        disabled
                        tabIndex={-1}
                        className="h-8 w-20 bg-gray-500 shadow-none hover:shadow-none"
                      >
                        &nbsp;
                      </Button>
                    </CardFooter>
                  </Card>
                ) : (
                  <WatchCard
                    watchDetail={value}
                    status={status}
                    onClick={() => navigate("/product-detail")}
                  />
                )}
              </div>
            ))}
          <div className={`sm:hidden flex items-center pr-4`}>
            <Button
              text={"View All"}
              isBorder={false}
              onClick={handleViewAll}
              width={"w-[100px] "}
              height={"h-[38px] "}
            />
          </div>
        </div>
      </div>

      <div className={`hidden sm:flex sm:justify-center`}>
        <Button
          text={"View All"}
          isBorder={false}
          onClick={handleViewAll}
          width={"w-[145px] md:w-[145px]"}
          height={"h-[64px] md:h-[56px]"}
        />
      </div>
    </>
  );
};

export default NewInWatchSection;
