import React from "react";
import Success_gif from "../assets/images/corrected.gif";
import RegLogo from "../assets/icons/reg_logo.svg";
import Button from "../components/Button";
import GmtMob from "../assets/images/gmt-mobile.png";
import Timer_icon from "../assets/icons/timer.svg";
import Card_icon from "../assets/icons/creditcard.svg";
import Location_icon from "../assets/icons/location_arrow.svg";
import GMTFeatures from "../components/GMTFeatures";
import CartItems from "../components/CartItems";
import { CardData } from "../constants/CartData";
import { useNavigate } from "react-router-dom";

const CheckoutSuccess = () => {
  const navigation = useNavigate();

  return (
    <div
      className={`flex flex-col sm:flex-row justify-between  bg-background800`}
    >
      <div className="flex flex-col mt-[44px]">
        <div
          className={` flex flex-col justify-center items-center  sm:items-start  mb-0 pl-0 sm:pl-8`}
        >
          <div
            className={`order-1 flex flex-col sm:flex-row  justify-center items-center mb-5`}
          >
            <div className={` w-[108px] sm:w-[135px] h-auto`}>
              <img src={Success_gif} />
            </div>
            <div className={`flel flex-col justify-center items-center`}>
              <div
                className={`flex justify-center items-center title text-white`}
              >
                <span className="text-4xl">Thank you!</span>
              </div>
              <span className={`din-text text-[#fbfbfb]/[.60] text-base pb-4`}>
                Your payment was successful
              </span>
            </div>
          </div>
          <div className="order-2 hidden sm:flex flex-col text-din-text mb-[14px]">
            <span className=" text-2xl text-haeder">Order Details</span>
            <span className=" text-sm font-normal text-white100">
              Order ID 123456789
            </span>
          </div>
          <div
            className={` sm:order-3 order-4  felx flex-row gap-4 text-sm text-din-text font-normal mt-[14px]  text-whiteSecondary/[.80] `}
          >
            <div
              className={`felx inline-flex justify-center items-center gap-[14px]`}
            >
              <img src={Timer_icon} />
              <span>Mon Jul 10th at 4pm - 8pm</span>
            </div>
            <div
              className={`felx inline-flex justify-center items-center gap-[14px]`}
            >
              <img src={Card_icon} />
              <span>KD 45 - Paid By KNET</span>
            </div>
            <div
              className={`felx inline-flex justify-center items-center gap-[14px]`}
            >
              <img src={Location_icon} />
              <span>Jabriya, Block 1, Street 7</span>
            </div>
          </div>
          <div className=" sm:order-4 order-3 mt-[14px] mb-4">
            <span className="text-2xl text-haeder mb-4">Items</span>
            <div className=" flex flex-col w-[343px] sm:w-[650px] pr-0 sm:pr-[38px] gap-3">
              {CardData.map((data, index) => (
                <CartItems key={index} data={data} />
              ))}
            </div>
          </div>
        </div>
        <div className="hidden sm:flex flex-row justify-between pt-4 pl-8 pr-[38px]">
          <div className="flex flex-col text-white100 ">
            <span className="text-base text-din-text">Sub total</span>
            <span className="text-base text-din-text">Shopping</span>
            <span className="text-lg text-title pt-[14px]">TOTAL</span>
          </div>
          <div className="flex flex-col text-white100 ">
            <span className="text-base text-din-text">KD 7,500</span>
            <span className="text-base text-din-text">KD 3</span>
            <span className="text-lg text-title pt-[14px]">KD 7,503</span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center sm:flex-row gap-4 sm:gap-[29px] mt-[25px]">
          <Button
            type="submit"
            text={"Continue Shopping"}
            isBorder={true}
            onClick={() => navigation("/")}
            width={"w-[343px] md:w-[314px]"}
            height={"h-[56px] md:h-[56px]"}
          />
          <Button
            type="submit"
            text={"Home"}
            isBorder={false}
            onClick={() => navigation("/")}
            width={"w-[343px] md:w-[314px]"}
            height={"h-[56px] md:h-[56px]"}
          />
        </div>
      </div>

      <div
        className={`hidden sm:flex flex-col justify-center items-center bg-black/[.40] pt-4`}
      >
        <div className={`flex flex-row`}>
          <div className={``}>
            <img src={RegLogo} />
          </div>
          <div className={`sm:hidden flex flex-center items-center `}>
            <span className={`text-2xl title uppercase text-Light_Gray`}>
              Download the new GMT24 app
              <br /> and experience more
            </span>
          </div>
        </div>
        <div className=" flex-col relative justify-start items-start pl-6 pr-[20px] sm:pl-14 sm:pr-[30px] ">
          <div className={`hidden sm:flex`}>
            <span className={`text-4xl title uppercase text-Light_Gray`}>
              Download the new GMT24 app
              <br /> and experience more
            </span>
          </div>
          <div className={`flex justify-start items-start pb-[6px] pl-0  `}>
            <span className={`text-Light_Gray din-text font-normal`}>
              Download the app and enjoy all the following
              <br /> features:
            </span>
          </div>
          <div>
            {/* <div className={`pb-[41px] din-text`}>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Negotiate Prices</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Special Offers</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Discover Merchants</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Sell your collection</span>
              </div>
            </div> */}
            <GMTFeatures />

            <div className={`pb-[37px] sm:pb-[18px]`}>
              <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
                <Button
                  type="submit"
                  text={"Download App"}
                  isBorder={false}
                  onClick={() => console.log("Download App")}
                  width={"w-[191px] md:w-[191px]"}
                  height={"h-[56px] md:h-[56px]"}
                />
              </a>
            </div>
            <div className={` hidden sm:block pb-[218px]`}>
              <Button
                type="submit"
                text={"Continue Shopping"}
                isBorder={true}
                onClick={() => navigation("/")}
                width={"w-[191px] md:w-[191px]"}
                height={"h-[56px] md:h-[56px]"}
              />
            </div>
            <div className={`w-[241px]  absolute bottom-0 right-0 pr-10 `}>
              <img className={``} src={GmtMob} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
