import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Category from "./containers/Category";
import ProductDetail from "./containers/ProductDetail";
import StoreDetail from "./containers/StoreDetails";
import Seller from "./containers/Seller";
import SignIn from "./containers/SignIn";
import { Registration_1 } from "./containers/Registration_1";
import Registration_2 from "./containers/Registration_2";
import Registration_3 from "./containers/Registration_3";
import SignUp from "./containers/SignUp";
import Registration_success from "./containers/Registration_Success";
import DownloadApp from "./containers/DownloadApp";
import CheckoutCart from "./containers/CheckoutCart";
import CheckoutSuccess from "./containers/CheckoutSuccess";
import MyAccount from "./containers/MyAccount";
import MyAddress from "./components/MyAddress";
import ContactUs from "./containers/ContactUs";
import PaymentFailed from "./containers/PaymentFailed";
import MyProfile from "./components/MyProfile";
import OrderHistory from "./containers/OrderHistory";
import LegalNotice from "./containers/footer/LegalNotice";
import DataPrivacyPolicy from "./containers/footer/DataPrivacyPolicy";
import Accessory from "./containers/Accessory";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="watch" element={<Category product_type={'watch'} />} />
        <Route path="accessory" element={<Accessory product_type={'accessory'}/>} />
        <Route path="product-detail" element={<ProductDetail />} />
        <Route path="store-detail" element={<StoreDetail />} />
        <Route path="seller" element={<Seller />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="registration_1" element={<Registration_1 />} />
        <Route path="registration_2" element={<Registration_2 />} />
        <Route path="registration_3" element={<Registration_3 />} />
        <Route path="registration-success" element={<Registration_success />} />
        <Route path="checkout-cart" element={<CheckoutCart />} />
        <Route path="checkout-success" element={<CheckoutSuccess />} />
        <Route path="payment-failed" element={<PaymentFailed />} />
        <Route path="account" element={<MyAccount />} />
        <Route path="profile" element={<MyProfile />} />
        <Route path="seller" element={<Seller />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="my-address" element={<MyAddress />} />
        <Route path="orders" element={<OrderHistory />} />
        <Route path="legal-notice" element={<LegalNotice />} />
        <Route path="privacy-policy" element={<DataPrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
