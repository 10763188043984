import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LeftArrow from "../assets/icons/Arrow-left.svg";
import { updateAddress } from "../store/user/userSlice";
import CustomInput from "./CustomInput";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("The First Name field is required"),
  // lastName: Yup.string().required("The Last Name field is required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    .required("The Email field is required"),
  // old_password: Yup.string()
  //   .min(8, "Password must be at least 8 characters")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
  //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  //   )
  //   .required("Password is required"),
  // new_password: Yup.string()
  //   .min(8, "Password must be at least 8 characters")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
  //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  //   )
  //   .required("Password is required"),
  // retype_new_password: Yup.string()
  //   .min(8, "Password must be at least 8 characters")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
  //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  //   )
  //   .required("Password is required"),
  // mobile_no: Yup.number().max(10, "Mobile Number should not exceed 10.").required("Please enter Mobile Number"),
mobile_no: Yup.string()
  .matches(/^[+\d-]+$/, "Mobile number must contain only digits, '+' and '-'")
  .min(10, "Mobile number must be at least 10 characters")
  .max(15, "Mobile number must be at most 15 characters")
  .required("Mobile number is required"),


  // birth_date: Yup.string().required("Please Enter Birth Date."),
  // country: Yup.string().required("Please Select Country."),
  // country_code: Yup.string().required("Please Select Country Code."),
});

function MyProfile() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);

  const update = (values) => {
    dispatch(
      updateAddress({
        first_name: values.first_name,
        last_name: userDetail?.billing?.last_name,
        email: values.email,
        country_id: userDetail?.billing?.country_id,
        phone: "1111111",
        // address_1: "chabbila",
        // city: "patan",z
        // phone:values?.mobile_no,
        address_1: userDetail?.billing?.address_1,
        civil_id:userDetail?.billing?.address_1,
        city:userDetail?.billing?.city,
        mobile:userDetail?.billing?.mobile,
        // mobile_country_code:userDetail?.billing?.country_code,
        mobile_country_code:"+91",
        country_code:userDetail?.billing?.country_code,
        country:userDetail?.billing?.country,
        civil_id_file_name:userDetail?.billing?.civil_id_file_name,
      })
    );
  };

  return (
    <Formik
      initialValues={{
        first_name: userDetail?.username,
        email: userDetail?.name,
        mobile_no: userDetail?.billing?.phone,
        email: userDetail?.email,
        // old_password: "",
        // new_password: "",
        // retype_new_password: "",
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={update}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={`flex flex-col sm:gap-4 bg-background800 h-screen`}>
            <div className="flex flex-col p-8 sm:w-2/3 ">
              <div className="flex flex-row gap-2 sm:justify-start justify-between">
                <img
                  src={LeftArrow}
                  alt="icon"
                  onClick={() => Navigate("/account")}
                  className="block sm:hidden"
                />
                <span className="title sm:din-text text-white text-base sm:text-2xl ">
                  Personal Info
                </span>
              </div>
              <CustomInput
                width={" w-full "}
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Name"
              />
              <span className={`text-gold text-xs mt-1`}>
                {errors.first_name && touched.first_name && errors.first_name}
              </span>
              <CustomInput
                width={" w-full "}
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="email"
              />
              <span className={`text-gold text-xs mt-1`}>
                {errors.email && touched.email && errors.email}
              </span>
              <CustomInput
                width={" w-full "}
                type="text"
                name="mobile_no"
                value={values.mobile_no}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Phone no."
              />
              <span className={`text-gold text-xs mt-1`}>
                {errors.mobile_no && touched.mobile_no && errors.mobile_no}
              </span>
            </div>
            {/* <div className="flex flex-col p-8 sm:w-2/3">
                <span className="text text-white text-2xl">
                  Change Password
                </span>
                <div
                  className={`w-full self-start sm:self-center border-b-[1px] border-opacity-20 border-whiteSecondary flex flex-row justify-start items-center py-4  gap-1`}
                >
                  <input
                    name="old_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.old_password}
                    className={`bg-transparent focus:outline-none text text-whiteSecondary text-base border-b-white100 focus:border-b-gold`}
                    placeholder="Old Password"
                  />
                </div>
                <span className={`text-gold text-xs mt-1`}>
                  {errors.old_password &&
                    touched.old_password &&
                    errors.old_password}
                </span>
                <div
                  className={`w-full self-start sm:self-center border-b-[1px] border-opacity-20 border-whiteSecondary flex flex-row justify-start items-center py-4  gap-1`}
                >
                  <input
                    name="new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password}
                    className={`bg-transparent focus:outline-none text text-whiteSecondary text-base border-b-white100 focus:border-b-gold`}
                    placeholder="New Password"
                  />
                </div>
                <span className={`text-gold text-xs mt-1`}>
                  {errors.new_password &&
                    touched.new_password &&
                    errors.new_password}
                </span>
                <div
                  className={`w-full self-start sm:self-center border-b-[1px] border-opacity-20 border-whiteSecondary flex flex-row justify-start items-center py-4  gap-1`}
                >
                  <input
                    name="retype_new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.retype_new_password}
                    className={`bg-transparent focus:outline-none text text-whiteSecondary text-base border-b-white100 focus:border-b-gold`}
                    placeholder="Retype New Password"
                  />
                </div>
                <span className={`text-gold text-xs mt-1`}>
                  {errors.retype_new_password &&
                    touched.retype_new_password &&
                    errors.retype_new_password}
                </span>
              </div> */}
            <div className="flex  justify-start sm:justify-end flex-col items-end p-8 sm:w-2/3">
              {/* <Button
                        type="submit"
                        text={"Save"}
                        isBorder={false}
                        onClick={() => console.log("Save Personal Detail")}
                        width={"w-[219px] md:w-[219px]"}
                        height={"h-[56px] md:h-[56px]"}
                    /> */}
              <button
                className=" rounded-full cursor-pointer bg-background900 text-tiny text-gold w-full sm:w-[219px] h-11 sm:h-14"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default MyProfile;
