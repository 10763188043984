import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import categorySlice from "./categorySlice";
import homeSlice from "./homeSlice";
import userSlice from "./user/userSlice";
import cartSlice from "./cartSlice";
import bannerImagesSlice from "./bannerImagesSlice";
import accessorySlice from "./accessorySlice";
import sellerSlice from "./sellerSlice";

const reducers = combineReducers({
  categories: categorySlice,
  accessories: accessorySlice,
  seller: sellerSlice,
  home: homeSlice,
  user: userSlice,
  cart: cartSlice,
  bannerImages: bannerImagesSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  // blacklist: ["Navigation"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
