import React from 'react'
import { arrowRight } from '../assets/icons'

const HeroButton = ({startingText, heading, middleText, actionText, onClick}) => {
  return (
    <div 
      className={`flex flex-col justify-center justify-items-center text-center border-gold border-solid border rounded-2xl cursor-pointer w-[343px] h-[191px] md:w-[370px] md:h-[191px] `} 
      onClick={() => onClick()}
    >
        <span className={`text-tiny text-white`}>{startingText}</span>
        <span className={`title-large`}>{heading}</span>
        {middleText && <span className={`text-tiny text-white`}>{middleText}</span>}
        {/* text-small */}
        <div className={`flex justify-center justify-items-center text-tiny text-white pt-[6px]`}>
            {actionText && <p className={`text-tiny text-gold`} style={{color: '#E5D062'}}>{actionText}</p>}
            <img src={arrowRight} alt="hoobank" className=" w-[24px] h-[24px] " />
        </div>
    </div>
  )
}

export default HeroButton