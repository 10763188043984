import React from "react";
import { useNavigate } from "react-router-dom";
import RIghtIcon from "../assets/icons/Arrow-right.svg";
import Left_icon from "../assets/icons/my-account/arrow-back.svg";
import Buttonn from "../components/Button";
import { OrderDetails } from "../constants/OrderDetails";
function OrderHistory() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/account");
  };
  return (
    <div className="bg-background800 px-6 sm:pl-8  din-text h-screen">
      <div className="flex gap-2 justify-between py-6">
        <img
          className="flex sm:hidden  "
          src={Left_icon}
          onClick={handleBack}
          alt="left_arrow"
        />
        <span className="sm:block text-white text-base sm:text-2xl title sm:din-text ">
          Order History
        </span>
      </div>
      <div className="flex flex-col justify-between gap-8">
        {OrderDetails.map((order, index) => (
          <div>
            <div key={index} className="flex flex-col gap-[14px] w-auto">
              <div>
                <span className="text-Light_Gray text-lg din-text">
                  ORDER #{order.orderNumber}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="flex justify-between gap-3 items-center">
                    <span className="text-white600 text-base">Placed On:</span>
                    <span className="text-sm sm:text-base text-white100 text-center">
                      {order.placedOn}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-white600 text-base ">Amount:</span>
                    <span className="text-sm sm:text-base text-white100 ">
                      {order.amount}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-white600 text-base">Status:</span>
                    <span className="text-sm sm:text-base text-white100 ">
                      {order.status}
                    </span>
                  </div>
                </div>
                <div className="pr-0 sm:pr-16">
                  <Buttonn
                    width={"w-[77px]"}
                    text={"Details"}
                    isBorder={false}
                    background={`bg-none`}
                    iconRight={RIghtIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderHistory;
