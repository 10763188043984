import React from 'react'
import { arrowRight } from '../assets/icons'
import { Rating } from "@material-tailwind/react";

function SellerReviewCard({customer_image, name, rating, review}) {
  return (
    <div className='flex flex-row gap-3 px-4 mt-6'>
        <div className='flex flex-row basis-1/6'>
            <img src={customer_image} className='object-contain w-[54px] h-[54px] rounded-full border-2 border-background900' />
        </div>
        <div className='flex flex-col basis-5/6'>
            <div className='flex flex-row justify-between gap-1 items-center' >
                <div className='flex flex-col gap-1  items-start '>
                    <span className='text text-gold '>{name}</span>
                    <Rating value={rating} readonly/>
                </div>
            </div>
            <div>
                <span className='text text-white text-sm'>{review}</span>
            </div>
        </div>
    </div>
  )
}

export default SellerReviewCard