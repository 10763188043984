import React from "react";
import Success_gif from "../assets/images/corrected.gif";
import RegLogo from "../assets/icons/reg_logo.svg";
import Button from "../components/Button";
import GmtMob from "../assets/images/gmt-mobile.png";
import GMTFeatures from "../components/GMTFeatures";
const Registration_success = () => {
  return (
    <div
      className={`flex flex-col justify-between sm:flex-row  items-center bg-background800`}
    >
      {/* <div className="flex flex-row justify-between items-center"> */}
      <div
        className={`w-1/2 flex flex-col justify-start items-start sm:justify-center sm:items-center mb-[52px] sm:mb-0 `}
      >
        <div
          className={`flex flex-row justify-start items-start sm:justify-center sm:items-center sm:flex-col `}
        >
          <div className={` w-[108px] sm:w-[135px] h-auto`}>
            <img src={Success_gif} alt="success_gif" />
          </div>
          <div className={`flel flex-col`}>
            <div
              className={`flex justify-center items-center title text-white`}
            >
              <span>Account created!</span>
            </div>
            <span className={`din-text text-[#fbfbfb]/[.60] text-base pb-4`}>
              Your account is ready.
            </span>
          </div>
        </div>
        <Button
          type="submit"
          text={"Explore Watches"}
          isBorder={true}
          onClick={() => console.log("Download App")}
          width={"w-[343px] md:w-[343px]"}
          height={"h-[56px] md:h-[56px]"}
        />
      </div>
      <div
        className={`w-2/2 flex flex-col justify-center items-center bg-black/[.40] pt-4`}
      >
        <div className={`flex flex-row`}>
          <div className={``}>
            <img src={RegLogo} />
          </div>
          <div className={`sm:hidden flex flex-center items-center `}>
            <span className={`text-2xl title uppercase text-Light_Gray`}>
              Download the new GMT24 app
              <br /> and experience more
            </span>
          </div>
        </div>
        <div className=" flex-col relative justify-start items-start pl-6 pr-[20px] sm:pl-14 sm:pr-[30px] ">
          <div className={`hidden sm:flex`}>
            <span className={`text-4xl title uppercase text-Light_Gray`}>
              Download the new GMT24 app
              <br /> and experience more
            </span>
          </div>
          <div className={`flex justify-start items-start pb-[6px] pl-0  `}>
            <span className={`text-Light_Gray din-text font-normal`}>
              Download the app and enjoy all the following
              <br /> features:
            </span>
          </div>
          <div>
            {/* <div className={`pb-[41px] din-text`}>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Negotiate Prices</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Special Offers</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Discover Merchants</span>
              </div>
              <div className="flex flex-row gap-[6px]  items-center text-whiteSecondary text-base sm:text-xl">
                {" "}
                <div className="w-3 h-3 rounded-full bg-gold"></div>
                <span>Sell your collection</span>
              </div>
            </div> */}
            <GMTFeatures />

            <div className={`pb-[37px] sm:pb-[18px]`}>
              <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
                <Button
                  type="submit"
                  text={"Download App"}
                  isBorder={false}
                  onClick={() => console.log("Download App")}
                  width={"w-[191px] md:w-[191px]"}
                  height={"h-[56px] md:h-[56px]"}
                />
              </a>
            </div>
            <div className={` hidden sm:block pb-[218px]`}>
              <Button
                type="submit"
                text={"Continue Shopping"}
                isBorder={true}
                onClick={() => console.log("Download App")}
                width={"w-[191px] md:w-[191px]"}
                height={"h-[56px] md:h-[56px]"}
              />
            </div>
            <div className={`w-[241px]  absolute bottom-0 right-0 pr-10 `}>
              <img className={``} src={GmtMob} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration_success;
