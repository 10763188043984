import React from "react";
import WhyUsCard from "./WhyCard";
import Easy_returns from "../assets/icons/Easy-returns.svg";
import Customer_service from "../assets/icons/Customer_service.svg";
import high_quality from "../assets/icons/high_quality.svg";
const YourMainComponent = () => {
  const cardData = [
    {
      imageUrl: Easy_returns,
      title: "Easy Returns",
      description:
        "Our return policy is simple and that is why customers love our shop.",
    },
    {
      imageUrl: Customer_service,
      title: "Customer Service",
      description: "We offer amazing customer service no matter what happens.",
    },
    {
      imageUrl: high_quality,
      title: "High Quality",
      description:
        "All of our products go through very strict inspection before we dispatch them.",
    },
  ];

  return (
    <div className={`flex flex-col justify-center items-center mt-10 sm:pb-10 `}>
      <div className={`flex flex-col justify-center items-center mb=10 sm:mb-8 gap-[2px]`}>
        <span className={`title-medium text-small-green`}>- Why Us</span>
        <span className={`title-medium sm:title-large text-haeder mb-3`}>WHY USE GMT-24</span>
      </div>
      <div className="flex flex-col mb-24 sm:mb-0 sm:flex-row gap-12 sm:gap-[120px] sm:mx-36">
        {cardData.map((card, index) => (
          <WhyUsCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default YourMainComponent;
