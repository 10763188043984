const OrderDetails = [
  {
    orderNumber: 21412,
    placedOn: "23th of January",
    amount: "KD 230.99",
    status: "Delivered",
  },
  {
    orderNumber: 21412,
    placedOn: "23th of January",
    amount: "KD 230.99",
    status: "Delivered",
  },
  {
    orderNumber: 21412,
    placedOn: "23th of January",
    amount: "KD 230.99",
    status: "Delivered",
  },
  {
    orderNumber: 21412,
    placedOn: "23th of January",
    amount: "KD 230.99",
    status: "Delivered",
  },
];
export {OrderDetails}