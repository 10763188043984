import React from "react";
import CancelIcon from "../assets/icons/cancel_icon.png";
import Button from "../components/Button";
const PaymentFailed = () => {
  return (
    <div className="bg-background800 flex flex-col justify-center items-center">
      <div className="flex justify-center items-center mt-[46px] mb-4">
        <img src={CancelIcon} />
      </div>
      <div>
        <span className="text-white text-2xl font-medium text-din">
          PAYMENT ERROR
        </span>
      </div>
      <div className="flex justify-center items-center mb-[26px]">
        <span className="text-white100/60 text-base text-center">
          There seems to be an issue with <br />
          your payment
        </span>
      </div>
      <div className="flex justify-center gap-4 flex-col">
      <Button
          text={"Retry"}
        //   background={'black/100'}
          isBorder={false}
          onClick={() => console.log("Download App")}
          width={"w-[343px] "}
          height={"h-[56px] md:h-[56px]"}
        />
        <Button
          text={"Home"}
          background={'black/100'}
          isBorder={true}
          onClick={() => console.log("Download App")}
          width={"w-[343px] "}
          height={"h-[56px] md:h-[56px]"}
        />
        </div>
    </div>
  );
};

export default PaymentFailed;
