import React from "react";
// import CustomDisk from "../assets/icons/custom-disk.svg";
import Button from "./Button";
import phone_banner from "../assets/images/ad_image.png";

const OurApp = () => {
  // const ulStyle = {
  //   listStyleImage: 'url("../assets/icons/custom-disk.svg")',
  // };
  return (
    <div className={`flex flex-col px-4 sm:flex-row sm:pl-[99px]`}>
      <div
        className={`order-2 sm:order-1 flex flex-col justify-center items-start basis-3/5 gap-8`}
      >
        <div
          className={`flex flex-col justify-center items-start sm:items-start gap-[6px]`}
        >
          <span className={`text-small-green `}>- Our App</span>
          <span className={`title text-2xl sm:text-5xl text-haeder`}>
            The all new GMT24 <br />
            App Does it all
          </span>
          <div className="flex flex-row gap-[6px] justify-cente items-center text-whiteSecondary text-base sm:text-xl">
            {" "}
            <div className="w-3 h-3 rounded-full bg-gold"></div>
            <span>Negotiate Prices</span>
          </div>
          <div className="flex flex-row gap-[6px] justify-cente items-center text-whiteSecondary text-base sm:text-xl">
            {" "}
            <div className="w-3 h-3 rounded-full bg-gold"></div>
            <span>Special Offers</span>
          </div>
          <div className="flex flex-row gap-[6px] justify-cente items-center text-whiteSecondary text-base sm:text-xl">
            {" "}
            <div className="w-3 h-3 rounded-full bg-gold"></div>
            <span>Discover Merchants</span>
          </div>
          <div className="flex flex-row gap-[6px] justify-cente items-center text-whiteSecondary text-base sm:text-xl">
            {" "}
            <div className="w-3 h-3 rounded-full bg-gold"></div>
            <span>Sell your collection</span>
          </div>
        </div>
        <div className="w-[100%]">
        <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
          <Button
            text={"Download App"}
            isBorder={false}
            onClick={() => console.log("Download App")}
            width={"w-[100%] sm:w-[239px]"}
            height={"h-[40px] md:h-[56px]"}
          />
          </a>
        </div>
      </div>
      <div className="order-1 sm:order-2">
        <img
          src={phone_banner}
          alt="Mobile Image"
          className="w-[400px] h-[300px] sm:w-[1030px] sm:h-[580px]"
        />
      </div>
    </div>
  );
};

export default OurApp;
