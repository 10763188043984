import React, { useState, useRef } from "react";
import { ThemeProvider } from "@material-tailwind/react";
import { arrowDown } from "../assets/icons";
import { cancel } from "../assets/icons";
import { Checkbox, Typography } from "@material-tailwind/react";

function Select({ text, options, setSelectedFilters, selectedFilters }) {
  const [isOpen, setIsOpen] = useState(false);

  const checkboxRef = useRef(null);

  const theme = {
    checkbox: {
      defaultProps: {
        color: "gold",
      },
      valid: {
        colors: ["gold"],
      },
      styles: {
        colors: {
          gold: {
            background: "checked:bg-amber-400",
            border: "checked:border-amber-400",
            // before: "checked:before:bg-green-500",
          },
        },
      },
    },
  };

  const handleCheckboxChange = (e, item) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedFilters((prevFilters) => [
        ...(Array.isArray(prevFilters) ? prevFilters : []),
        { label: item.label, value: item.value, filter: text },
      ]);
    } else {
      setSelectedFilters((prevFilters) =>
        (Array.isArray(prevFilters) ? prevFilters : []).filter(
          (filter) => !(filter.value === item.value && filter.filter === text)
        )
      );
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-between items-start  py-4 sm:pr-10 pr-8 ">
        <div
          className={`h-[48px] w-[160px] flex flex-row  items-center justify-between px-4 my-4 cursor-pointer bg-backgroundButton border-gold border-solid border rounded-[10px]`}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <text className={`text-tiny text-white`}>{text}</text>
          <img src={arrowDown} alt="icon" className="ml-2" />
        </div>
      </div>
      <div
        className={` bg-background1000 border-solid border] rounded-[10px] border border-Light_Gray w-[190px] absolute overflow-y-auto h-56 ;  ${
          isOpen ? "flex flex-col " : "hidden"
        }`}
      >
        <div className="flex flex-row items-center  justify-between p-3  ">
          <div className="text-white text-start">{text}</div>
          <img
            src={cancel}
            alt="icon"
            className="ml-2"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
        </div>

        <div className="flex flex-col items-start justify-center ">
          {options?.map((item, index) => (
            <ThemeProvider value={theme}>
              <Checkbox
                key={item.value}
                // color="amber"
                value={item.value}
                checked={
                  selectedFilters.filter(
                    (filterItem, index) => filterItem.label === item.label
                  )?.length
                    ? true
                    : false
                }
                label={
                  <Typography className="text-xs text-tiny text-white">
                    {item.label}
                  </Typography>
                }
                ref={checkboxRef}
                onChange={(e) => handleCheckboxChange(e, item)}
              />
            </ThemeProvider>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Select;
