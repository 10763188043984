import React from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom';

const HeroAction = () => {
  const handleMobileDownload = ()=>{}
  const navigate = useNavigate();
  return (
    <div className={`flex flex-col justify-center items-start w-[343px] md:w-[472px]`}>
        <div className={`flex flex-col gap-[6px]`}>
          <span className={`text-small-green`}>- Luxury Watches</span>
          <span className={`title-medium sm:title-xl`}>The Best Watches<br/>ONLY AT GMT24</span>
          <p className={`text-default text-sm sm:text-small opacity-60`}>Watches fully checked and inspeted by GMT24<br/>once received from merchant and before<br/>delivery to buyer</p>
        </div>
        <div className={`flex flex-row justify-center items-center mt-6 gap-4 mb-6`}>
            <Button text={'Browse Watches'} onClick={() => navigate("/watch")} width={'w-[162px] md:w-[228px]'} height={'h-[48px] md:h-[56px]'}/>
            <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
            <Button  text={'Download App'} isBorder={true} onClick={handleMobileDownload} width={'w-[162px] md:w-[228px]'} height={'h-[48px] md:h-[56px]'}/>
            </a>
        </div>
    </div>
  )
}

export default HeroAction