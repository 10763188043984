import React from 'react'
import Footer from '../../components/Footer'

function LegalNotice() {
  return (
    <div className={` bg-background800 no-scrollbar`}>
      <div className="px-4 py-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-6 text-haeder">Terms and Conditions</h1>
      <p className="mb-4 opacity-60 text-white">
        Welcome to <strong className='text-haeder'>The GMT 24!</strong>
      </p>
      <p className="mb-4 opacity-60 text-white">
        These terms and conditions outline the rules and regulations for the use of GMT General Trading Co's Website, located at thegmt24.com.
      </p>
      <p className="mb-4 opacity-60 text-white">
        By accessing this website, we assume you accept these terms and conditions. Do not continue to use <strong>The GMT 24</strong> if you do not agree to take all of the terms and conditions stated on this page.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Cookies</h2>
      <p className="mb-4 opacity-60 text-white">
        We employ the use of cookies. By accessing The GMT 24, you agreed to use cookies in agreement with GMT General Trading Co's Privacy Policy.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">License</h2>
      <p className="mb-4 opacity-60 text-white">
        Unless otherwise stated, GMT General Trading Co and/or its licensors own the intellectual property rights for all material on The GMT 24. All intellectual property rights are reserved. You may access this from The GMT 24 for your own personal use subjected to restrictions set in these terms and conditions.
      </p>
      <ul className="list-disc ml-5 mb-4 opacity-60 text-white">
        <li>Republish material from The GMT 24</li>
        <li>Sell, rent or sub-license material from The GMT 24</li>
        <li>Reproduce, duplicate or copy material from The GMT 24</li>
        <li>Redistribute content from The GMT 24</li>
      </ul>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Comments</h2>
      <p className="mb-4 opacity-60 text-white">
        Parts of this website offer an opportunity for users to post and exchange opinions and information. GMT General Trading Co does not filter, edit, publish or review Comments prior to their presence on the website.
      </p>
      <p className="mb-4 opacity-60 text-white">
        You warrant and represent that your Comments do not invade any intellectual property rights or contain any defamatory, offensive, or unlawful material.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Hyperlinking to our Content</h2>
      <p className="mb-4 opacity-60 text-white">
        The following organizations may link to our Website without prior written approval: Government agencies, search engines, news organizations, and more. 
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">iFrames</h2>
      <p className="mb-4 opacity-60 text-white">
        Without prior approval and written permission, you may not create frames around our Webpages that alter the visual presentation or appearance of our Website.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Content Liability</h2>
      <p className="mb-4 opacity-60 text-white">
        We are not responsible for content that appears on your website. You agree to protect and defend us against all claims that arise on your website.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Disclaimer</h2>
      <p className="mb-4 opacity-60 text-white">
        To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and its use.
      </p>
      <h2 className="text-2xl font-bold mb-4 text-haeder">Cancellation Policy</h2>
      <p className="mb-4 opacity-60 text-white">
        In case of cancellation after any purchase, we will deduct our fee from your total amount before we do the refund.
      </p>
      <p className="text-center font-bold text-white">The GMT 24 &copy; {new Date().getFullYear()}</p>
    </div>
    <Footer/>
    </div>
  )
}

export default LegalNotice
