import React from 'react'
import Footer from '../../components/Footer'

function DataPrivacyPolicy() {
  return (
    <div className={` bg-background800 no-scrollbar`}>

       <div className="px-4 py-8 max-w-4xl mx-auto">
      <h1 className="font-bold text-haeder text-center mb-6 text-3xl">Privacy Policy for GMT General Trading Co</h1>
      <p className="opacity-60 text-white mb-4">
        At <strong>The GMT 24</strong>, accessible from thegmt24.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by The GMT 24 and how we use it.
      </p>
      <p className="opacity-60 text-white mb-4">
        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at <a href="mailto:info@thegmt24.com" className="text-blue-500 underline">info@thegmt24.com</a>.
      </p>
      <h2 className="font-bold text-haeder mb-4">Log Files</h2>
      <p className="opacity-60 text-white mb-4">
        The GMT 24 follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable.
      </p>
      <h2 className="font-bold text-haeder mb-4">Cookies and Web Beacons</h2>
      <p className="opacity-60 text-white mb-4">
        Like any other website, The GMT 24 uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
      </p>
      <h2 className="font-bold text-haeder mb-4">Google DoubleClick DART Cookie</h2>
      <p className="opacity-60 text-white mb-4">
        Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to our website and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads" className="text-blue-500 underline">Google Privacy Policy</a>.
      </p>
      <h2 className="font-bold text-haeder mb-4">Third Party Privacy Policies</h2>
      <p className="opacity-60 text-white mb-4">
        The GMT 24's Privacy Policy does not apply to other advertisers or websites. We advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed information.
      </p>
      <h2 className="font-bold text-haeder mb-4">Children's Information</h2>
      <p className="opacity-60 text-white mb-4">
        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
      </p>
      <p className="opacity-60 text-white mb-4">
        The GMT 24 does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
      </p>
      <h2 className="font-bold text-haeder mb-4">Online Privacy Policy Only</h2>
      <p className="opacity-60 text-white mb-4">
        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in The GMT 24. This policy is not applicable to any information collected offline or via channels other than this website.
      </p>
      <h2 className="font-bold text-haeder mb-4">Consent</h2>
      <p className="opacity-60 text-white mb-4">
        By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
      </p>
      <p className="text-center font-bold opacity-60 text-white">The GMT 24 &copy; {new Date().getFullYear()}</p>
    </div>
    <Footer/>
    </div>
  )
}

export default DataPrivacyPolicy
