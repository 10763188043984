import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios.js";

export const fetchAllProducts = createAsyncThunk(
  "categories/fetchAllProducts",
  async ({ per_page, page, categoryId }) => {
    const response = await axiosInstance.post("/gmt/get_all_products", {
      per_page,
      page,
      order: "desc",
      orderby: "approval_date",
      on_sale: null,
      categoryId,
      product_type: 'watch',
    });
    return {
      data: response.data.result,
      is_more: response.data.result?.length === per_page ? true : false,
    };
  }
);

export const getRequiredData = createAsyncThunk(
  "categories/getRequiredData",
  async ({ lang }) => {
    const response = await axiosInstance.post("gmt/get_product_required_data", {
      lang: "en",
    });
    return response?.data?.result;
  }
);

const initialState = {
  categoryProducts: {
    status: "idle",
    products: [],
    error: null,
    is_more: true,
  },
  getCategories: {
    status: "idle",
    products: "",
    error: null,
    is_more: true,
  },
};

const categorySlice = createSlice({
  name: "categories",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRequiredData.pending, (state) => {
        state.getCategories.status = "loading";
      })
      .addCase(getRequiredData.fulfilled, (state, action) => {
        state.getCategories.status = "succeeded";
        state.getCategories.products = action.payload;
      })
      .addCase(getRequiredData.rejected, (state, action) => {
        state.getCategories.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllProducts.pending, (state) => {
        state.categoryProducts.status = "loading";
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.categoryProducts.status = "succeeded";
        state.categoryProducts.products = [
          ...state.categoryProducts.products,
          ...action.payload.data,
        ];
        state.categoryProducts.is_more = action.payload.is_more;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.categoryProducts.status = "failed";
        state.categoryProducts.error = action.error.message;
      });
  },
});

export const selectAllCategoryProducts = (state) => state?.categories?.categoryProducts;
export const filterProductData = (state) => state?.categories?.getCategories;

export default categorySlice.reducer;
