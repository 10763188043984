import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios.js";

export const getBannerImages = createAsyncThunk(
  "home/get_website_banner_images",
  async ({ }) => {
    const response = await axiosInstance.post(
      "/gmt/get_website_banner_images",
      { },
      { withCredentials: false }
    );
    return response.data.result;
  }
);

const initialState = {
  status: "idle",
  data: [],
  error: null,
};

const bannerImagesSlice = createSlice({
  name: "bannerImages",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getBannerImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBannerImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getBannerImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

// export const bannerImages = (state) => state.bannerImages;
export default bannerImagesSlice.reducer;
