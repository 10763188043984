const CardData = [
    {
      title: "Rolex Oyster",
      subtitle: "Perpetual",
      itemId: "11823409",
      currency: "KD",
      price: "2400",
    },
    {
      title: "Rolex Oyster",
      subtitle: "Perpetual",
      itemId: "11823409",
      currency: "KD",
      price: "2400",
    },
    {
        title: "Rolex Oyster",
        subtitle: "Perpetual",
        itemId: "11823409",
        currency: "KD",
        price: "2400",
      },
  ];
  export { CardData };