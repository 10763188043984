import React from "react";
import { useNavigate } from "react-router-dom";
const AccessoriesCard = ({ accessoryDetail }) => {
  const navigation = useNavigate();

  return (
    <div
      className={`w-[212px] h-[350px] cursor-pointer border-[1px] border-[#000000]/[.30]`}
      onClick={() =>
        navigation("/product-detail", {
          state: { watchDetail: accessoryDetail },
        })
      }
      style={{
        // borderRadius: '10px',
        background: "linear-gradient(136deg, #201D1D 50.15%, #181515 100%))",
        // boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <div className={`flex justify-center items-center basis-1/3`}>
        <img
          src={accessoryDetail.images[0].src}
          alt="hoobank"
          className="w-[204px] h-[234px] object-cover"
        />
      </div>
      <div className={`flex flex-col items-start p-4 gap-2 basis-1/3`}>
        <div className={`flex flex-col gap-[3px] `}>
          <span className={`text-tiny text-white opacity-60`}>
            {accessoryDetail?.name}
          </span>
          <span className={`text-small text-haeder`}>
            {accessoryDetail?.model}
          </span>
          <div className={`flex items-end text-default opacity-60 text-white`}>
            <span className={`text-[10px]`}>
              {accessoryDetail?.product_currency?.code}
            </span>
            <span className={`text-xs`}>{accessoryDetail?.price}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessoriesCard;
