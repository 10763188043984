import React, { useEffect, useState } from "react";
import { Stepper, Step, Input } from "@material-tailwind/react";
import RightArrow from "../assets/icons/Arrow-right.svg";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const Registration_2 = () => {
  const Navigate = useNavigate();

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    setOtpValues(newOtpValues);

    // Move to the next input field if the current input has a value
    if (value !== "" && index < otpValues.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleBackspace = (index) => {
    // Move to the previous input field if the current input is empty
    if (index > 0 && otpValues[index] === "") {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }

    const newOtpValues = [...otpValues];
    newOtpValues[index] = "";

    setOtpValues(newOtpValues);
  };

  const [activeStep, setActiveStep] = useState(2);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  return (
    <div className="flex flex-col justify-center items-center py-4 px-8  bg-background800 ">
      <div
        className={`flex flex-col bg-neutral-900 gap-4 w-auto sm:w-[604px] sm:h-[703px]  pt-0 my-11 sm:pt-8 sm:mt-[75px] sm:bg-gradient-to-b from-[#201D1D] via-[#201D1D] to-[#181515] rounded-lg`}
      >
        <div className={` hidden  sm:flex justify-center items-center pb-4 `}>
          <Stepper
            className={`w-[200px] `}
            activeStep={activeStep}
            isLastStep={(value) => setIsLastStep(value)}
            isFirstStep={(value) => setIsFirstStep(value)}
          >
            <Step
              className={`bg-transparent border-2 border-sky-500 `}
              style={{
                borderColor: "#E5D062",
                backgroundColor: activeStep === 1 ? "#E5D062" : "#181515",
                color: activeStep === 1 ? "black" : "#E5D062",
              }}
              onClick={() => setActiveStep(0)}
            >
              1
            </Step>
            <Step
              className={`bg-gold border-2 border-sky-500 `}
              style={{
                borderColor: "#E5D062",
                backgroundColor: activeStep === 2 ? "#E5D062" : "#181515",
                color: activeStep === 2 ? "black" : "#E5D062",
              }}
              onClick={() => setActiveStep(1)}
            >
              2
            </Step>
            <Step
              className={`bg-transparent border-2 border-sky-500 `}
              style={{
                borderColor: "#E5D062",
                backgroundColor: activeStep === 3 ? "#E5D062" : "#181515",
                color: activeStep === 3 ? "black" : "#E5D062",
              }}
              onClick={() => setActiveStep(2)}
            >
              3
            </Step>
          </Stepper>
        </div>
        <div className={`flex flex-col justify-center items-center`}>
          <div className="flex justify-center items-center text-lg text-white pb-4 sm:pb-0">
            <span className={` text-center`}>
              ENTER THE FOUR DIGIT CODE <br /> SENT TO:
            </span>
          </div>
          <div
            className={`flex justify-center items-center text-gold text-base pb-4  sm:pb-1`}
          >
            <span>+965 99887766</span>
          </div>
          <div
            className={`flex justify-center items-center text-center pb:[29px] sm:pb-[21px]`}
          >
            <span className={`text-sm opacity-50 text-Light_Gray`}>
              We've sent a 4 digit code to your phone number.
              <br />
              Please enter the verification code.
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center pb-[337px] sm:pb-0">
          {/* <div>
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />{" "}
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />{" "}
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />{" "}
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />{" "}
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />{" "}
            <input
              maxlength="1"
              className={`outline-none text-center text-white text-3xl bg-transparent border-b-2  border-b-white100 focus:border-b-gold w-[295px] sm:w-14`}
              type="text"
              name="password"
            />
          </div> */}
          <div className={`flex gap-2`}>
            {otpValues.map((value, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                maxLength="1"
                className={`outline-none text-center text-white text-3xl bg-transparent border-b-2 pb-4 border-b-white100 focus:border-b-gold w-14 sm:[295px]`}
                type="text"
                name="password"
                value={value}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    handleBackspace(index);
                  }
                }}
              />
            ))}
          </div>
        </div>
        <div className={`flex justify-center items-center text-center`}>
          <span className={` text-xs text-white`}>
            Didn’t receive the SMS?
            <br />
            Request new code in 04:15
          </span>
        </div>
        <div
          className={`flex justify-center items-end sm:justify-center sm:items-center`}
        >
          <Button
            type="submit"
            text={"Submit Code"}
            isBorder={false}
            onClick={() => Navigate("/registration_3")}
            width={"w-[165px] md:w-[165px]"}
            height={"h-[56px] md:h-[56px]"}
            iconRight={RightArrow}
          />
        </div>
      </div>
    </div>
  );
};

export default Registration_2;
