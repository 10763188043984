import React from 'react';
import SmallImage from './SmallImage';
import Cart_img from "../assets/images/rolex_oyster_cart.png";
import { useSelector } from 'react-redux';

const CartItems = ({ width, data }) => {


  const cartDetail = useSelector((state) => state?.cart?.cartDetail);

  
  return (
    <div className={`flex flex-row justify-between w-${width}`}>
      <div className="flex ">
        {/* <img className="w-[100px] h-[90px] pr-4" src={Test} alt="Product" /> */}
        <div 
        className={`pr-4`}
        >
        <SmallImage
        src={data?.product?.images[0]?.src}
        alt={"Cart img"}
        width_px={"100"}
        height_px={"90"}
        />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-din-text text-white">
            {data?.product?.name}
            {/* <br /> {data?.product.subtitle} */}
          </span>
          <span className="text-[14px] text-whiteSecondary font-normal">  
            Item ID: {data?.product.id}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center text-white">
        <div>
          <span className="text-xs mb-1">{data?.product?.product_currency?.code}</span>{" "}
          <span className="text-xl">{data?.product?.price}</span>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
