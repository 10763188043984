import React from "react";

const CustomInput = ({
  width,
  placeholder,
  name,
  type,
  input_icon,
  height,
  onChange,
  onBlur,
  value,
  ...respProps
}) => {
  const handleChange = (e) => {
    console.log("Input change event:", e);
    onChange(e);
  };

  const handleBlur = (e) => {
    console.log("Input blur event:", e);
    onBlur(e);
  };
  return (
    <div>
      <input
        {...respProps}
        name={name}
        type={type}
        placeholder={placeholder}
        className={`outline-none text-white border-opacity-10 bg-transparent border-b-[1px] text border-b-white100 focus:border-b-gold h-14 ${width} ${height}`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {input_icon && (
        <img
          src={input_icon}
          className="absolute mr-2 w-10"
          alt="Search Icon"
        />
      )}
    </div>
  );
};

export default CustomInput;
