import React from 'react'
import Banner from "../assets/images/category.png";

const CategoryBanner = () => {
  return (
    <div>
    <img src={Banner} />
  </div>
  )
}

export default CategoryBanner