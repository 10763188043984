// Fetch Banner Images
// Fetch All Category wise Product

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios.js";

const watch_categories = [
  {
    tag: "high_demand",
    name: "High Demand",
    display: "Two Layer",
  },
  {
    tag: "recently_added",
    name: "Recently Added",
    display: "One Layer Watch",
  },
  {
    tag: "best_selling",
    name: "Best Selling",
    display: "One Layer Watch",
  },
  {
    tag: "rochenbold",
    name: "Rochenbold ",
    display: "One Layer Accessory",
  },
  {
    tag: "most_viewed",
    name: "Most Viewed",
    display: "One Layer Watch",
  },
  {
    tag: "recently_sold",
    name: "Recently Sold",
    display: "One Layer Watch",
  },
];

export const fetchAllCategories = createAsyncThunk(
  "home/fetchAllCategories",
  async () => {
    const response = await axiosInstance.post("/gmt/get_categories", {});
    return response.data.result;
  }
);

export const fetchProductByCategory = createAsyncThunk(
  "home/fetchProductByCategory",
  async ({
    categoryId,
    page,
    per_page,
    filter,
    name,
    isShowAll,
    product_type,
    isShowSold,
  }) => {
    const response = await axiosInstance.post(
      "/gmt/get_products_by_category_id",
      {
        categoryId,
        per_page,
        page,
        status: "publishAndSold",
        filter,
        name,
        isShowAll,
        currency_id: "",
        order: "desc",
        product_type,
        isShowSold,
      }
    );
    return {
      data: response.data.result,
      is_more: response.data.result?.length === per_page ? true : false,
      category: categoryId,
    };
  }
);

const getCategoriesInitialState = () => {
  let categories_initial_state = {};

  watch_categories.map((item, index) => {
    categories_initial_state[item.tag] = {
      status: "idle",
      products: [],
      error: null,
      is_more: true,
    };
  });
  return categories_initial_state;
};

const initialState = {
  categoriesObj: {
    status: "idle",
    categories: [],
    error: null,
  },
  categoryProducts: {
    status: "idle",
    products: [],
    error: null,
    is_more: true,
  },
  ...getCategoriesInitialState(),
};

const homeSlice = createSlice({
  name: "home",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.categoriesObj.status = "loading";
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.categoriesObj.status = "succeeded";
        state.categoriesObj.categories = action.payload.data;
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.categoriesObj.status = "failed";
        state.categoriesObj.error = action.error.message;
      })

      .addCase(fetchProductByCategory.pending, (state, action) => {
        let category = watch_categories.filter(
          (value, index) => value.name === action.meta.arg?.categoryId
        );
        category = category?.length && category[0]?.tag;
        if (category) {
          state[category].status = "loading";
        }
      })
      .addCase(fetchProductByCategory.fulfilled, (state, action) => {
        let category = watch_categories.filter(
          (value, index) => value.name === action.meta.arg?.categoryId
        );
        category = category?.length && category[0]?.tag;
        if (category) {
          state[category].status = "succeeded";
          state[category].products = [
            ...state[category].products,
            ...action.payload.data,
          ];
          state[category].is_more = action.payload.is_more;
        }
      })
      .addCase(fetchProductByCategory.rejected, (state, action) => {
        let category = watch_categories.filter(
          (value, index) => value.name === action.meta.arg?.categoryId
        );
        category = category?.length && category[0]?.tag;
        if (category) {
          state[category].status = "failed";
          state[category].error = action.error.message;
        }
      });
  },
});

export const selectAllCategories = (state) => state?.home?.categoriesObj;
export const select_high_demand = (state) => state?.home?.high_demand;
export const select_recently_added = (state) => state?.home?.recently_added;
export const select_most_viewed = (state) => state?.home?.most_viewed;
export const select_recently_sold = (state) => state?.home?.recently_sold;
export const select_best_selling = (state) => state?.home?.best_selling;
export default homeSlice.reducer;
