import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export const logoutUser = createAsyncThunk(
  "user/logout",
  async ({ rejectWithValue }) => {
    try {
      await axiosInstance.post("/gmt/logout2", {}, {});

      return null;
    } catch (error) {
      console.error("Logout failed:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
