// User Personal Info
// Alter User Personal Info
// User Address
// Alter User Address

// User Order History

// import { persistor } from "../index.js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios.js";
import { logoutUser } from "./userThunk.js";

export const loginUser = createAsyncThunk(
  "user/login",
  async ({ user_name, password }) => {
    const response = await axiosInstance.post(
      "/gmt/login",
      { user_name: user_name, password: password },
      { withCredentials: true }
    );
    return response.data.result;
  }
);

// export const logoutUser = createAsyncThunk("user/logout", async ({}) => {
//   const response = await axiosInstance.post("/gmt/logout2", {}, {});

//   response.data.result
//   persistor.pause();
//   persistor.flush().then(() => persistor.purge());
//   return response.data.result;
// });

// export const logoutUser = createAsyncThunk(
//   "user/logout",
//   async ({ rejectWithValue }) => {
//       try {
//         await axiosInstance.post("/gmt/logout2", {}, {});
//           return null;
//       } catch (error) {
//           console.error('Logout failed:', error);
//           return rejectWithValue(error.response?.data || error.message);
//       }
//   }
// );

// export const updateUserProfile = createAsyncThunk(
//   "user/updateProfile",
//   async ({
//     first_name,
//     last_name,
//     country_id,
//     phone,
//     phone_country_code,
//     birth_date,
//     email,
//     gender,
//     address_1,
//     civil_id,
//     city,
//     mobile,
//     mobile_country_code,
//     country_code,
//     country,
//   }) => {
//     const response = await axiosInstance.post(
//       "/gmt/update_profile",
//       {
//         first_name,
//         last_name,
//         country_id,
//         phone,
//         phone_country_code,
//         birth_date,
//         email,
//         gender,
//         address_1,
//         civil_id,
//         city,
//         mobile,
//         mobile_country_code,
//         country_code,
//         country,
//       },
//       { withCredentials: true }
//     );
//     return response.data.result;
//   }
// );

export const updateAddress = createAsyncThunk(
  "user/updateAddress",
  async ({
    first_name,
    last_name,
    country_id,
    phone,
    email,
    address_1,
    civil_id,
    city,
    mobile,
    mobile_country_code,
    country_code,
    country,
    civil_id_file_name,
    civil_id_attachment,
  }) => {
    const response = await axiosInstance.post(
      "/gmt/update_profile2",
      {
        first_name,
        last_name,
        country_id,
        phone,
        email,
        address_1,
        civil_id,
        city,
        mobile,
        mobile_country_code,
        country_code,
        country,
        civil_id_file_name,
      },
      { withCredentials: true }
    );
    return response.data.result;
  }
);

const initialState = {
  status: "idle",
  userDetail: {},
  error: null,
};

const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetail = action.payload?.customers;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // .addCase(updateUserProfile.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(updateUserProfile.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.userDetail = action.payload?.customers;
      // })
      // .addCase(updateUserProfile.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error.message;
      // })

      .addCase(updateAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetail = action.payload?.customers;
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetail = initialState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// export const userDetails = (state) => state.userDetails;
export default userSlice.reducer;
